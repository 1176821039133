<template>
  <div style="max-width:1000px;">
    <div class="maintitle">
      <span v-if="form.uuid==0">添加</span>
      <span v-else>修改</span>家政公司
    </div>
    <el-divider></el-divider>
    <el-form ref="form" :model="form" label-width="120px" :rules="rules">
      <el-form-item label="编号" prop="no">
        <el-input v-model="form.no" maxlength="20" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="公司名称" prop="company">
        <el-input v-model="form.company" maxlength="16" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="联系人" prop="contact">
        <el-input v-model="form.contact" maxlength="4" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="tel">
        <el-input v-model="form.tel" maxlength="11"></el-input>
      </el-form-item>
      <el-form-item label="统一信用编码" prop="credit_code">
        <el-input v-model="form.credit_code" maxlength="19"></el-input>
      </el-form-item>
      <el-form-item label="状态" class="text-left" v-if="form.uuid != ''">
        <el-radio-group v-model="form.status">
          <el-radio :label="1">启用</el-radio>
          <el-radio :label="2">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- <el-form-item v-if="form.uuid != ''" label="所在地区" class="text-left">
        <span>{{form.area_text}}</span>
      </el-form-item>-->
      <el-form-item label="修改地区" class="text-left">
        <el-checkbox-group v-model="form.arealist">
          <el-checkbox
            v-for="(item,index) in district"
            :key="index"
            :label="item.id+'-'+item.fullname"
            :value="item.id"
          >{{item.fullname}}</el-checkbox>
        </el-checkbox-group>
        <div>
          <span
            v-for="(item,index) in form.arealist"
            :key="index"
            class="margin-right"
            @click="delarea(item)"
          >{{item.split('-')[1]}}</span>
        </div>
      </el-form-item>
    </el-form>
    <el-button @click="add">
      <span v-if="form.uuid==0">添加</span>
      <span v-else>修改</span>
    </el-button>
  </div>
</template>

<script>
export default {
  data() {
    let self = this;
    return {
      form: {
        uuid: "", //家政公司主键（编号）
        company: "", //家政公司名称
        contact: "", //联系人
        tel: "", //家政公司联系电话
        arealist: [], //家政公司所在地区
        status: 1, //家政公司状态
        credit_code: "", //身份证号码
        no: "", //编号
      },
      rules: {
        no: [{ required: true, message: "请输入家政公司编号", trigger: "blur" }],
        company: [
          { required: true, message: "请输入家政公司名称", trigger: "blur" },
          { min: 2, max: 16, message: "长度在 2 到 16 个汉字", trigger: "blur" }
        ],
        contact: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
          { min: 2, max: 4, message: "长度在 2 到 4 个汉字", trigger: "blur" }
        ],
        tel: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          { min: 11, max: 11, message: "联系电话格式不正确", trigger: "blur" }
        ],
        credit_code: [
          { required: true, message: "请输入统一信用编码", trigger: "blur" },
        ]
      },
      district: [] //县、区列表
    };
  },
  methods: {
    getinfo: function() {
      let id = this.$route.params.id;
      if (!id) {
        return;
      }
      this.$axios({
        url: this.HOST + "/jiameng/jiazheng/getinfo",
        method: "post",
        data: { id: id }
      }).then(res => {
        if (res.data.status == 1) {
          this.form = res.data.info;
          this.center = [];
        }
      });
    },
    add: function() {
      this.$axios({
        url: this.HOST + "/jiameng/jiazheng/add",
        method: "post",
        data: {
          info: this.form
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.$router.push("/jiazheng");
        } else if (res.data.status == 0) {
          this.$message({
            type: "error",
            message: res.data.info
          });
        }
      });
    },
    getAreaList() {
      let url = this.HOST + "/jiameng/index/getAreaList";
      this.$axios({
        method: "post",
        url: url,
        data: {}
      }).then(res => {
        if (res.data.status == 1) {
          this.district = res.data.list;
        } else {
          return [];
        }
      });
    },
    delarea(str) {
      this.$confirm("确定要删除" + str.split("-")[1] + "吗？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let i = this.form.arealist.indexOf(str);
          this.form.arealist.splice(i, 1);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    position(str) {
      if (str) {
        let p = JSON.parse(str);
        return [parseFloat(p.lng), parseFloat(p.lat)];
      } else {
        return [0, 0];
      }
    }
  },
  computed: {},
  created: function() {
    this.getinfo();
    this.getAreaList();
  }
};
</script>

<style>
#container {
  width: 100%;
  height: 300px;
}
</style>